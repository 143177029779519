<template>
  <div class="contentbody">
    <b-loading is-full-page :active.sync="isLoading" :can-cancel="false"></b-loading>
    <div class="contentleft" v-if="w.left" v-bind:class="{ contentleftbig: w.right }">
      <div class="contentleft-inner">
        <div class="contentleft-header">
          <div class="mr5">
            <b-button icon-left="plus" type="is-info" @click="CreateCompare()">New </b-button>
          </div>
          <div class="taskSearchSel">
          <b-select v-model="ActiveGoalId" icon="bullseye-arrow" @input="setActiveGoal();doSearch();" expanded>
            <option v-for="(g, gi) in GoalList" :key="gi" :value="g._id">{{g.goaltitle}}</option>
          </b-select>
        </div>
          <div class="contentleft-header-searchbox">
            <b-input placeholder="Search" type="search" icon="search" v-model="searchField" @input="doSearch()" ></b-input>
          </div>
        </div>

        <div class="contentleft-body scrolly scrollx">
          <div class="tsk lgreybg p11 m11" v-if="compareList.length === 0">No comparisons created</div>
          
          <div class="tsk p0 m7 isPointer isFlex isFlexCol tskshadow dcard" v-for="(n, index) in compareList" :key="index">
            <div class="txtc mt11 mb11" @click="openCompare(n, index)">
              <font-awesome-icon :icon="['fal', 'exchange-alt']" size="2x"></font-awesome-icon>
            </div>
            <div class="isFlexGrow" @click="openCompare(n, index)">
              <div class="txtc txtbold mb3 mt5">{{ n.title }}</div>
              <div class="txtsm txtc"><span v-if="n.goaltitle !== ''" :style="`color: ${n.goalcolor}`">{{n.goaltitle}}</span><span v-else>Not associated with a goal</span></div>
              <div class="txtc txtsm mb3 mt5">{{ n.oneliner }}</div>
            </div>
            <div class="isFlex isFlexJustify isFlexWrap vlgreybg roundedbottom p5">
              <b-button class="mr11" size="is-small" type="is-light" icon-left="exchange-alt" @click="openCompareModal(n, index)">Compare</b-button>
              <b-button size="is-small" type="is-light" icon-left="edit" @click="openCompare(n, index)">Edit Details</b-button>
            </div>
          </div>
        </div>

        <div class="p5 isFlex">
          <div class="isFlexGrow mr11">
            <b-pagination
              @change="getCompare($event)"
              :total="total"
              :current="current"
              :range-before="rangeBefore"
              :range-after="rangeAfter"
              :order="order"
              :size="size"
              :simple="isSimple"
              :rounded="isRounded"
              :per-page="perPage"
              :icon-prev="prevIcon"
              :icon-next="nextIcon"
              aria-next-label="Next page"
              aria-previous-label="Previous page"
              aria-page-label="Page"
              aria-current-label="Current page"
            >
            </b-pagination>
          </div>
        </div>
      </div>
    </div>
    <div class="contentright" v-if="w.right">
      <div class="contentright-inner">
        <div class="contentright-nav">
          <div class="iconbutton mv5" @click="closeRight()">
            <font-awesome-icon :icon="['fal', 'times']" size="lg" ></font-awesome-icon>
          </div>
          <div v-if="ActiveCompare.canEdit">
            <div v-if="!isEdit">
              <div class="iconbutton mv5" @click="isEdit=true">
                <font-awesome-icon :icon="['fal', 'edit']" size="lg" ></font-awesome-icon>
              </div>
            </div>
            <div v-if="isEdit">
              <div class="iconbutton mv5" @click="isEdit=false">
                <font-awesome-icon :icon="['fal', 'eye']" size="lg" ></font-awesome-icon>
              </div>
            </div>
            <div style="height: 20px;"></div>
            <div class="iconbutton mv11 bluetxt11" @click="compareSave()">
              <font-awesome-icon :icon="['fal', 'save']" size="lg" ></font-awesome-icon>
            </div>
            <div class="iconbutton mv11 red" @click="compareDelete()">
              <font-awesome-icon :icon="['fal', 'trash']" size="lg" ></font-awesome-icon>
            </div>
          </div>
        </div>
        <div class="contentright-body ">
          <div class="contentright-body-body scrolly">

            <div class="p11">
              <!-- attach to goal: start -->
              <div class="pt9 mb5" v-if="isEdit">
                <div class="field" v-if="isadmin"><b-switch v-model="ActiveCompare.demo">Demo</b-switch></div>
                <div class="txtbold mb3">Associated with goal...</div>
                  <b-select placeholder="Select Goal" expanded v-model="ActiveCompare.goalid" @input="changeGoal">
                <option v-for="gc in GoalList" :value="gc._id" :key="gc._id">{{ gc.goaltitle }}</option>
                </b-select>
              </div>
              <div v-if="!isEdit" class="txtsm mb5">Goal: <span v-if="ActiveCompare.goaltitle !== ''">{{ActiveCompare.goaltitle}}</span><span v-else>Not associated with a goal</span></div>

              <!-- Title: start -->
              <div class="mt5 mb5" v-if="isEdit">
                <div class="txtbold mb3">Give your comparison a name...</div>
                <b-input v-model="ActiveCompare.title" placeholder="Please enter a title"></b-input>
              </div>
              <div v-if="!isEdit" class="txth5 txtbold mb5">{{ActiveCompare.title}}</div>
              <!-- Oneliner: start -->
              <div class="mt5 mb5" v-if="isEdit">
                <div class="txtbold mb3">Short description that will appear in the listings.</div>
                <b-input v-model="ActiveCompare.oneliner" placeholder="Customer profile"></b-input>
              </div>
              <div v-if="!isEdit" class="mb5">{{ActiveCompare.oneliner}}</div>
              <!-- description -->
              <div class="mt5 mb11" v-if="isEdit">
                <div class="txtbold mb3">Description</div>
                <b-input v-model="ActiveCompare.description" placeholder="Please enter a title" type="textarea" rows="3"></b-input>
              </div>
              <div v-if="!isEdit && ActiveCompare.description !== ''" class="mb5">{{ActiveCompare.description}}</div>
              
            </div>
            

            <div>
              
            </div>

          </div>
          <div class="p11 bluebg11" v-if="ActiveCompare.type === 'Chat' || ActiveCompare.type === 'Request'">
            <div><b-input v-model="newReply" maxlength="500" rows="2" type="textarea" placeholder="Post a reply"></b-input></div>
            <div class="buttons">
              <b-button expanded @click="postReply">Post Reply</b-button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Vue from "vue";
import { EventBus } from '../event-bus.js';
import { Utils } from '../mixins/utils.js';
import { Schemas } from "@/schemas/all.js";
import debounce from "lodash/debounce";
import array from "lodash/array";
import { windowSplit2 } from "../mixins/windowSplit.js";
import draggable from "vuedraggable";
import ModalCompare from '../modals/ModalCompare.vue';

Vue.component("pillbox", {
  template: "pillbox-template",
  props: {
    label: String,
    // value: undefined,
    width: { type: String, default: '100px' },
  },
  data: function () {
    return {
      lb: this.label,
      // vl: this.value,
      st: `width: ${this.width};`,
    }
  },
  template: `<div class="mb5 isFlex isFlexAlign"><div class="roundedleft lgreybg p5 pl11" style="width: 100px;">{{lb}}</div><div class="p5 isFlexGrow vlgreybg roundedright"><span><slot></slot></span></div></div>`});

export default {
  mixins: [Utils, windowSplit2, Schemas],
  components: {
    draggable
  },
  name: 'Compare',
  data() {
    return {
      editor: null,
      compareList: [],
      searchField: '',
      ActiveCompare: {},
      ActiveCompareId: -1,
      ActiveId: -1,
      ActiveRow: {},
      isEdit: false,
      showEditor: false,
      canEdit: false,
      isNew: false,

      isLoading: true,
      GoalList: [],
      sub: localStorage.getItem("sub"),

      // pagination: start
      searchField: "",
      perPage: 20,
      prodCount: 0,
      total: 0,
      current: 1,
      rangeBefore: 3,
      rangeAfter: 1,
      order: "",
      size: "",
      isSimple: false,
      isRounded: false,
      prevIcon: "chevron-left",
      nextIcon: "chevron-right",
      // pagination: end

      isadmin: localStorage.getItem("isadmin"),
    }
  },
  created() {
    this.getGoalSelect();
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    this.getCompare(0);
  },
  methods: {
    
    async getGoals() {
      this.GoalList = [];

      const glin = await this.DataGet("/goals/select");
      const gl = glin;

      for (const g of gl) {
        // set style
        if (g.goalcolor === "") {
          g.goalcolor = "#CCCCCC";
        }
        const nr = {
          _id: g._id,
          goaltitle: g.goaltitle,
          goalcolor: g.goalcolor
        }
        this.GoalList.push(nr);
      }
      if(!this.isNew) {
        this.GoalList.push({
        _id: "",
        goaltitle: "Not associated with a goal",
        goalcolor: "#CCC"
      });
      }
      this.loading = false;
    },

    doSearch: debounce(function() {
      this.current = 1;
      this.total = 0;
      this.getCompare(0);
    }, 2000),

    async getCompare(type) {
      this.isLoading = true;

      const sendData = {
        searchField: this.searchField,
        goalid: this.ActiveGoalId,
        perPage: this.perPage,
        skip: 0
      };
      this.current = type;
      if (type === 0) {
        this.current = 1;
        let getcount = await this.DataSend('post','/compares/listcount', sendData, 'rows');
        let totalRows = getcount;
        if (totalRows > this.perPage) {
          totalRows = totalRows / this.perPage;
          totalRows = Math.ceil(totalRows);
          this.total = totalRows * this.perPage;
        } else {
          this.total = totalRows;
        }
      }

      if (type > 1) {
        sendData.skip = this.perPage * (type - 1);
      }

      const gnotify = await this.DataSend('post', '/compares', sendData, 'rows');
      
      this.compareList = gnotify;
      this.isLoading = false;
    },

    async openCompare(row, index, isEdit=false) {
      this.ActiveCompareId = index;
      this.editor = null;
      this.showEditor = false;
      this.isLoading = true;
      this.isNew = false;
      this.isEdit = false;
      const gc = await this.DataGet(`/compare/${row._id}`);
      this.ActiveCompare = gc;
      this.canEdit = gc.canEdit;
      if (gc.canEdit) {
        this.isEdit = true;
      }
      this.openRight();
      this.isLoading = false;
    },

    changeGoal(data){
      if (data !== '') {
        const gindex = array.findLastIndex(this.GoalList, { '_id': data });
        this.ActiveCompare.goalid = data;
        this.ActiveCompare.goaltitle = this.GoalList[gindex].goaltitle;
        this.ActiveCompare.goalcolor = this.GoalList[gindex].goalcolor;
      } else {
        this.ActiveCompare.goalid = '';
        this.ActiveCompare.goaltitle = '';
        this.ActiveCompare.goalcolor = '';
      }
    },

    async CreateCompare() {
      const newcompare = await this.DataGet('/comparenew');
      this.compareList.unshift(newcompare);
      this.openCompare(newcompare, 0, true);
    },

    async compareSave() {
      const dosave = await this.DataSend('put', '/compare', this.ActiveCompare, 'rows', true);
      this.compareList[this.ActiveCompareId].title = dosave.title;
      this.compareList[this.ActiveCompareId].type = dosave.type;
      this.compareList[this.ActiveCompareId].oneliner = dosave.oneliner;
    },

    async compareDelete() {
      this.$buefy.dialog.confirm({
        title: "",
        message:
        "Are you sure you want to <b>delete</b> this comparison? This cannot be undone.",
        confirmText: "Delete comparison",
        type: "is-danger",
        hasIcon: true,
        icon: 'exclamation',
        onConfirm: async () => {
          this.closeRight();
          const delcompare = await this.DataDelete(`/compare/${this.ActiveCompare._id}`, "row");
          this.compareList.splice(this.ActiveCompareId, 1);
        }
      });
    },

    openCompareModal(row, index) {
      this.ActiveRow = row;
      this.ActiveId = index;
      this.modal = this.$buefy.modal.open({
        parent: this,
        component: ModalCompare,
        hasModalCard: true,
        fullScreen: true,
        canCancel: false,
        props: {
          data: this.ActiveRow,
        },
        events: {
          dataFromModal: value => {}
        }
      });
    },

    


  },
  computed: {
    
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
    // EventBus.$off('task_open');
    // EventBus.$off('task_added');
  }
}
</script>

<style>
.ProseMirror {
  /* border: 1px solid #CCC; */
  min-height: 300px;
  padding: 5px;
}
.ProfileDesc > p{
  margin-bottom: 10px;
}
</style>
