<template>
  <div class="modal-card" :style="ModalWidth">
    <header class="modal-card-head" style="padding: 10px;">
      <p class="modal-card-title">{{Comp.title}}</p>
    </header>
    <section class="modal-card-body" style="position: relative;" id="modalbody">
      <b-loading :active.sync="isLoading" :can-cancel="false" :is-full-page="false"></b-loading>
      <table class="ctable">
        <tbody>
          <tr v-for="(hr, hi) in Comp.data" :key="hi">
            <td style="width: 250px !important;">
              <template v-if="hr.type === 'headers'">
                <div class="isFlex">
                  <div class="isFlexGrow">{{hr.title}}</div>
                </div>
              </template>
              <template v-else>
                <template v-if="showNewFeature">
                  <div class="mb3"><b-input v-model="hr.title" size="is-small" @input="doSave"></b-input></div>
                  <b-select placeholder="Select..." v-model="hr.type" size="is-small" expanded @input="doSave">
                    <option value="text">Text</option>
                    <option value="link">Link</option>
                    <option value="select">Select</option>
                  </b-select>
                  <div class="isFlex isFlexEnd mt3">
                    <div><b-button icon-left="trash" size="is-small" @click="rowDelete(hi)">Delete</b-button></div>
                    <div class="ml3 mr3"><b-button icon-left="arrow-alt-up" size="is-small" @click="moveRowUp(hi)"></b-button></div>
                    <div><b-button icon-right="arrow-alt-down" size="is-small" @click="moveRowDown(hi, Comp.data.length)"></b-button></div>
                    <div></div>
                  </div>
                </template>
                <template v-else>
                  <div>{{hr.title}}</div>
                </template>
              </template>
            </td>
            <td style="width: 250px !important;" v-for="(hc, ci) in hr.cols" :key="ci" :class="useStyle(hc.text, hi)">
              <template v-if="isEdit">
                <div v-if="hr.type === 'select'">
                  <b-select placeholder="Select..." v-model="hc.text" size="is-small" expanded  @input="doSave">
                    <option value="">Don't Know</option>
                    <option value="Not sure">Not sure</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                    <option value="Maybe">Maybe</option>
                    <option value="Yes but...">Yes but...</option>
                    <option value="Must have">Must Have</option>
                  </b-select>
                  <b-input class="mt3" placeholder="Explain..." size="is-small" v-model="hc.note" type="textarea" rows="2" v-if="hc.text === 'Yes but...' || hc.text === 'Must have' || hc.text === 'Maybe'" @input="doSave"></b-input>
                </div>
                <template v-if="hr.type === 'text'">
                  <div>
                    <b-input placeholder="Enter text..." size="is-small" v-model="hc.text" type="textarea" rows="2" @input="doSave"></b-input>
                  </div>
                </template>
                <template v-if="hr.type === 'link'">
                  <div>
                    <b-input placeholder="https://..." size="is-small" v-model="hc.text" @input="doSave"></b-input>
                  </div>
                </template>
                <template v-if="hr.type === 'headers'">
                  <div>
                    <b-input placeholder="Header" size="is-small" v-model="hc.text" @input="doSave"></b-input>
                  </div>
                </template>
              </template>
              <template v-else>
                <div v-if="hi === 0">
                  {{hc.text}}
                </div>
                <div v-else class="txtc p5">
                  <div v-if="hr.type === 'link'"><a :href="hc.text" target="_blank">{{hc.text}}</a></div>
                  <div v-else>{{hc.text}}</div>
                  <div class="txtsm">{{hc.note}}</div>
                </div>
              </template>
            </td>
          </tr>
          <tr v-if="showNewFeature || isEdit">
            <td class="p3 lgreybg">
              <div v-if="showNewFeature">
                <div>
                  <b-select placeholder="Select..." v-model="newFeatureType" size="is-small" expanded>
                    <option value="select">Select</option>
                    <option value="text">Text</option>
                    <option value="link">Link</option>
                  </b-select>
                </div>
                <div class="mt3 mb3"><b-input placeholder="Enter a feature" size="is-small" v-model="newFeature" @keyup.native.enter="featureAdd()"></b-input></div>
                <div class="mb3"><b-button size="is-small" expanded @click="featureAdd()">Add</b-button></div>
              </div>
            </td>
            <td v-for="(hf, ci) in firstRow.cols" :key="ci" class="p11 lgreybg">
              <div class="isFlex isFlexJustify">
                <div><b-button icon-left="arrow-alt-left" size="is-small" @click="moveColLeft(ci)"></b-button></div>
                <div class="ml3 mr3"><b-button icon-left="trash" size="is-small" @click="colDelete(ci)">Delete</b-button></div>
                <div><b-button icon-right="arrow-alt-right" size="is-small" @click="moveColRigth(ci, firstRow.cols.length)"></b-button></div>
                <div></div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </section>
    <footer class="modal-card-foot isFlex" style="padding: 10px;"> 
      <div class="isFlexGrow">
        <b-button @click="$parent.close()">
          Close
        </b-button>
      </div>
      <div v-if="canEdit">
        <template>
          <b-button icon-left="edit" @click="(showNewFeature) ? showNewFeature=false:showNewFeature=true">Edit structure</b-button>
          <b-button icon-left="plus" @click="colAdd">Add Column</b-button>
        </template>
        <b-button @click="(isEdit) ? isEdit=false:isEdit=true">
          <span v-if="isEdit">View</span>
          <span v-else>Edit</span>
        </b-button>
      </div>
    </footer>
  </div>
</template>

<script>
import { Utils } from "@/mixins/utils.js";
import { Schemas } from "@/schemas/all.js";
import debounce from "lodash/debounce";
export default {
  name: "ModalMapForm",
  mixins: [Utils, Schemas],
  props: {
    data: Object,
  },
  data() {
    return {
      ModalWidth: `width: auto`,
			Comp: {},
			isEdit: false,
			canEdit: false,
      canArc: false,
      isLoading: true,
      newFeature: '',
      newFeatureType: 'select',
      showNewFeature: false,
      firstRow: {},
    };
  },
  created() {
		this.loadCompare();
  },
  methods: {
    useStyle(state, hi) {
      switch (state) {
        case 'Yes':
          return 'cyes'
          break;
        case 'No':
          return 'cno'
          break;
        case 'Not sure':
          return 'cnots'
          break;
        case 'Maybe':
          return 'cmaybe'
          break;
        case 'Yes but...':
          return 'cyesbut'
          break;
        case 'Must have':
          return 'cmust'
          break;
      
        default:
          if (hi === 0) {
            return 'bluebg8 white txtc txtbold';
          } else {
            return '';
          }
          break;
      }
    },
		async loadCompare() {
			const inid = this.data._id;
			this.Comp = await this.DataGet(`/compare/${inid}`)
      this.canEdit = this.Comp.canEdit;
      if (this.Comp.data.length === 0) {
        this.Comp.data = [
          {
            title: 'Compare',
            type: 'headers',
            cols: [
              { text: 'Column 1', note: '', },
              { text: 'Column 2', note: '', },
            ]
          },
          {
            title: 'Feature 1',
            type: 'text',
            cols: [
              { text: 'No', note: '',},
              { text: 'Yes', note: '' },
            ]
          },
          {
            title: 'Feature 2',
            type: 'select',
            cols: [
              { text: 'Yes', note: '' },
              { text: 'Yes but...', note: 'This is a note' },
            ]
          },
        ]
      }
      this.firstRow = this.Comp.data[0];
      this.isLoading = false;
		},

    featureAdd() {
      const colcnt = this.Comp.data[0].cols.length;
      if (this.newFeature === '') {
        this.$buefy.snackbar.open({ message: 'Enter some text', type: 'is-warning', position: 'is-bottom' });
        return;
      }
      const nr = {
        title: this.newFeature,
        type: this.newFeatureType,
        cols: [],
      }
      for (let i = 0; i < colcnt; i++) {
        nr.cols.push({ text: '', note: '' });
      }

      this.Comp.data.push(nr);
      this.newFeature = '';
      this.newFeatureType = 'select';
      this.doSave();
    },

    colAdd() {
      let cnt = 1;
      for (const rows of this.Comp.data) {
        if(cnt === 1) {
          rows.cols.push({ text: 'New', note: '', })
        } else {
          rows.cols.push({ text: '', note: '', })
        }
        cnt += 1;
      }
      this.doSave();
    },

    colDelete(i) {
      this.$buefy.dialog.confirm({ 	
        title: '',	
        message: 'Are you sure you want to <b>delete</b> this column?',	
        confirmText: 'Delete',	
        type: 'is-danger',	
        hasIcon: true,	
        icon: 'exclamation',	
        onConfirm: () => {
          for (const rows of this.Comp.data) {
            rows.cols.splice(i, 1);
          }
          this.doSave();
        }
       });
    },

    moveColLeft(i) {
      if (i === 0) { return }
      let newpos = i - 1;
      for (const rows of this.Comp.data) {
         this.moveEach(rows.cols, i, newpos);
      }
      this.doSave();
    },
    moveColRigth(i, total) {
      if (i < total - 1) { 
        let newpos = i + 1;
        for (const rows of this.Comp.data) {
          this.moveEach(rows.cols, i, newpos);
        }
      }
      this.doSave();
    },

    rowDelete(i) {
      this.$buefy.dialog.confirm({ 	
        title: '',	
        message: 'Are you sure you want to <b>delete</b> this feature?',	
        confirmText: 'Delete',	
        type: 'is-danger',	
        hasIcon: true,	
        icon: 'exclamation',	
        onConfirm: () => {
          this.Comp.data.splice(i, 1);
          this.doSave();
        }
       });
    },

    moveRowUp(i) {
      if (i === 1) { return }
      let newpos = i - 1;
      this.moveEach(this.Comp.data, i, newpos);
      this.doSave();
    },

    moveRowDown(i, total) {
      if (i < total - 1) { 
        let newpos = i + 1;
        this.moveEach(this.Comp.data, i, newpos);
      }
      this.doSave();
    },

    moveEach(arr, old_index, new_index) {
      if (new_index >= arr.length) {
        var k = new_index - arr.length + 1;
        while (k--) {
          arr.push(undefined);
        }
      }
      arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
      return arr; // for testing
    },

    doSave: debounce(function() {
      this.SaveComp(false);
    }, 2000),

    async SaveComp(close) {
      if(this.canEdit) {
        this.DataSend('put', '/compare', this.Comp);
      }
      // this.$emit("dataUpdated", { type, data: updata.rows });
      // if (close) {
      //   this.$parent.close();
      // }
    },
  }
};
</script>

<style>
  .cyes {
    background-color: #E3FBE9 !important;
  }
  .cyes select {
    background-color: #E3FBE9 !important;
  }
  .cno {
    background-color: #FFEAEA !important;
  }
  .cno select {
    background-color: #FFEAEA !important;
  }
  .cnots {
    background-color: #EFEFEF !important;
  }
  .cnots select {
    background-color: #EFEFEF !important;
  }
  .cmaybe {
    background-color: #FFEBD9 !important;
  }
  .cmaybe select, .cmaybe textarea {
    background-color: #FFF9F4 !important;
  }
  .cyesbut {
    background-color: #C0E7F3 !important;
  }
  .cyesbut select, .cyesbut textarea {
    background-color: #EFF9FC !important;
  }
  .cmust {
    background-color: #DBDBFF !important;
  }
  .cmust select, .cmust textarea {
    background-color: #EEEEFF !important;
  }
  /* .ctext select, textarea {
    background-color: #ffffff !important;
  } */

.ctable {
  border-collapse:collapse;
  width: 100%;
}
.ctable tbody tr:nth-child(2n+1){
  background:white !important;
}
.ctable tbody tr:nth-child(2n+2){
  background:#f8f8f8 !important;
}
.ctable tbody tr:last-child {
  border-radius: 0 7px 7px;
}
.ctable td:first-child {
  padding-left: 11px !important;
}
.ctable th:first-child {
  padding-left: 11px !important;
}
.ctable td, th {
  border-right: 1px solid #cccccc !important;
  padding: 5px;
}
</style>
